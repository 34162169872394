import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../context/authContext";



const ChatHeader = ({ title, showSelect, docDrafter }) => {


  const navigate = useNavigate();

  const { logout, selectedOption, setSelectedOption } = useContext(AuthContext);
  const handleLogOut = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/auth/logout`);
      logout();
      navigate("/");
    } catch (error) {
      navigate('/')
    }
  };

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    switch (selectedValue) {
      case "Chat With My Docs":
        navigate("/home/chatWithDoc");
        break;
      case "My Documents":
        navigate("/home/myDocs");
        break;
      case "Add a Document":
        navigate("/home/addDoc");
        break;
      default:
        break;
    }
  };

  return (
    <div className="ChatView_Header">
      <div className="">
        <p>{title}</p>
      </div>
      <div className="ChatView_Icons_Container">
        {showSelect && (
          <select
            className="text-xs"
            value={selectedOption}
            onChange={handleOptionChange}
          >
            <option value="Chat With My Docs">Chat With My Docs</option>
            <option value="" style={{ fontSize: "5px" }} disabled>
              &nbsp;
            </option>
            <option value="My Documents">My Documents</option>
            <option value="" style={{ fontSize: "5px" }} disabled>
              &nbsp;
            </option>
            <option value="Add a Document">Add a Document</option>
          </select>
        )}

        {docDrafter && (
          <button>My Templates</button>
        )}

        <div className="flex gap-x-3 items-center py-2">

          <div>
            <FontAwesomeIcon icon={faUser} />
          </div>
          <div>
            <FontAwesomeIcon icon={faRightFromBracket} onClick={handleLogOut} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;
