import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import ReactMarkdown from 'react-markdown';
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FileText, Loader2 } from 'lucide-react';
import Wand from "../../../../img/Wand2.png";
import Download_Button from "../../../../img/Download_Button.png";
import useSendDataToDatabase from '../../../../data/prompts/useSendDataToDatabase';
import { useGetChatMessages } from "../../../../data/chats/use-get-chat-messages";
import { htmlToText } from "html-to-text";
import { REACT_APP_BACKEND_URL } from "../../../../data/constants";

interface Artifact {
    type: "response" | "contract";
    content: string;
    id: string;
    title: string;
}

interface Message {
    id: number;
    user: string;
    llm: string;
    artifacts: Artifact[];
}

interface LocationState {
    prompt?: string;
    context?: string;
    templateName?: string;
}

const UseTemplatePage: React.FC = () => {
    const { chatId } = useParams<{ chatId: string }>();
    const location = useLocation();

    const { sendDataToDatabase } = useSendDataToDatabase();
    const { data: existingMessages, isLoading: isLoadingMessages, isError } = useGetChatMessages(chatId, !!chatId);

    const [messages, setMessages] = useState<Message[]>([]);
    const [inputMessage, setInputMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRightSideVisible, setIsRightSideVisible] = useState<boolean>(false);
    const [currentArtifact, setCurrentArtifact] = useState<Artifact | null>(null);
    
    // Extract context and initial prompt from navigation state
    const locationState = location.state as LocationState;
    const initialPrompt = locationState?.prompt || "";
    const initialContext = locationState?.context || "";
    const templateName = locationState?.templateName || "";

    useEffect(() => {
        if (existingMessages) {
            const convertedMessages: Message[] = existingMessages.map(msg => ({
                id: Date.now() + Math.random(), // generate unique ID
                user: msg.promptText,
                llm: msg.response,
                artifacts: [], // You might want to modify this if you store artifacts differently
            }));

            setMessages(convertedMessages);
        }
    }, [existingMessages]);

    // Handle initial prompt or context from navigation
    useEffect(() => {
        if (initialPrompt || initialContext) {
            handleSendMessage(initialPrompt, initialContext);
        }
    }, [initialPrompt, initialContext]);

    const handleDownloadArtifact = async () => {
        if (!currentArtifact) return;

        // Create a temporary container to render markdown to HTML
        const tempContainer = document.createElement('div');
        const reactMarkdownElement = <ReactMarkdown>{currentArtifact.content}</ReactMarkdown>;

        // Use ReactDOM.createRoot for React 18
        const root = ReactDOM.createRoot(tempContainer);
        root.render(reactMarkdownElement);

        // You might need to wait for rendering to complete
        await new Promise(resolve => setTimeout(resolve, 0));

        // Convert the rendered HTML to plain text
        const plainText = htmlToText(tempContainer.innerHTML, {
            wordwrap: 130,
            preserveNewlines: true,
        });

        try {
            const response = await axios.post(
                `${REACT_APP_BACKEND_URL}/v1/document/download`,
                {
                    content: plainText,
                    title: currentArtifact.title,
                },
                { responseType: "blob" }
            );

            // Create a download link for the file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${currentArtifact.title}.docx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading document:", error);
            alert("Failed to download the document. Please try again.");
        }
    };


    const handleSendMessage = async (message: string, context?: string) => {
        if (!message.trim() || !chatId) return;

        // Create user message
        const userMessage: Message = {
            id: Date.now(),
            user: message,
            llm: "",
            artifacts: [],
        };

        // Add user message to the list
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setInputMessage(""); // Clear the input field
        setIsLoading(true);

        try {
            let response;
            const baseConfig = {
                id: 1, // You might want to generate a unique ID
                prompt: message,
                chat_id: chatId,
            };

            if (context) {
                // Use DCC endpoint if context is provided
                response = await axios.post(
                    "https://ai-server.lawlyfy.ai/api/wakili_ai_doc_drafter_DCC",
                    {
                        ...baseConfig,
                        context: context
                    }
                );
            } else {
                // Use NCC endpoint if no context
                response = await axios.post(
                    "https://ai-server.lawlyfy.ai/api/wakili_ai_doc_drafter_NCC",
                    baseConfig
                );
            }

            const parsedResponse = JSON.parse(response.data.response);

            // Create LLM response message
            const llmMessage: Message = {
                id: Date.now() + 1,
                user: message,
                llm: parsedResponse.response_list.find((r: any) => r.type === "response")?.content || "Response received.",
                artifacts: parsedResponse.response_list.map((artifact: any) => ({
                    type: artifact.type as "response" | "contract",
                    content: artifact.content,
                    id: artifact.id || Math.random().toString(36).substring(2),
                    title: artifact.title || "Untitled",
                })),
            };

            // Send data to database
            sendDataToDatabase({
                chatId: chatId,
                promptText: message,
                response: llmMessage.llm,
                citations: [], // Empty as there are no citations
            });

            // Replace the last user-only message with the LLM response
            setMessages((prevMessages) => [...prevMessages.slice(0, -1), llmMessage]);

        } catch (error: any) {
            console.error("Error fetching response:", error);

            // Display an error message
            const errorMessage: Message = {
                id: Date.now() + 2,
                user: message,
                llm: "Sorry, there was an error processing your request. Please try again.",
                artifacts: [],
            };

            setMessages((prevMessages) => [...prevMessages.slice(0, -1), errorMessage]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleContractClick = (artifact: Artifact) => {
        setCurrentArtifact(artifact);
        setIsRightSideVisible(true);
    };

    const closeRightPanel = () => {
        setIsRightSideVisible(false);
        setCurrentArtifact(null);
    };
    return (
        <div className="flex flex-col md:flex-row min-h-[85vh] max-h-[85vh] bg-gray-100">
            {/* Left Side: Chat Interface */}
            <div className={`Lighter_Purple_Shade rounded-2xl transition-all duration-300 ${isRightSideVisible ? "md:w-1/2" : "md:w-full"} p-4 flex flex-col`}>
                {/* Chat Messages */}
                <div className="flex-grow overflow-y-auto space-y-4 mb-4">
                    <div className='w-full md:w-4/5 mx-auto'>
                        <h1 className='font-medium text-center text-lg'>Type or upload a doc with relevant information for AI-assisted drafting</h1>
                    </div>

                    {messages.map((msg) => (
                        <div key={msg.id} className="flex flex-col items-end">
                            {/* User Message */}
                            <div className="Light_Purple_Shade max-w-[80%] text-white rounded-[16px] py-3 px-4 flex justify-between items-center leading-loose mb-2">
                                {msg.user}
                            </div>

                            {/* LLM Response */}
                            {msg.llm && (
                                <div className="bg-white max-w-[80%] self-start rounded-[16px] py-3 px-4 justify-between items-center leading-loose mb-2">
                                    <ReactMarkdown>{msg.llm}</ReactMarkdown>

                                    {msg.artifacts.map((artifact) => (
                                        <div
                                            key={artifact.id}
                                            className={`flex items-center mt-2 ${artifact.type === "contract"
                                                ? "text-green-600 cursor-pointer hover:bg-green-100"
                                                : "text-blue-600"
                                                } p-2 rounded`}
                                            onClick={() => handleContractClick(artifact)}
                                        >
                                            {artifact.title !== "Untitled" && <FileText className="mr-2" size={16} />}
                                            {artifact.title !== "Untitled" && artifact.title}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}

                    {isLoading && (
                        <div className="flex justify-center items-center">
                            <Loader2 className="animate-spin text-indigo-500" size={32} />
                        </div>
                    )}
                </div>

                {/* Input Section */}
                <div className="ChatView_Footer w-full m-auto">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSendMessage(inputMessage);
                        }}
                        className="flex flex-col md:flex-row h-auto items-center relative"
                    >
                        <div className="relative w-full md:w-[80%] flex items-center mb-2 md:mb-0">
                            <textarea
                                value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                                placeholder="What would you like to draft today?"
                                disabled={isLoading}
                                className={`w-full px-3 py-2 h-12 border border-gray-300 focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-500 resize-none ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            ></textarea>

                            <img
                                src={Download_Button}
                                alt="download button"
                                className="h-6 absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-indigo-500 cursor-pointer"
                                onClick={() => console.log("Download clicked")}
                            />
                        </div>

                        <button
                            type="submit"
                            disabled={isLoading}
                            className={`w-full md:w-[20%] h-12 flex justify-center items-center bg-gradient-to-r from-linearBlue1 to-linearBlue2 text-white text-lg ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            {isLoading ? <Loader2 className="animate-spin" /> : 'Draft'}
                            {!isLoading && <img src={Wand} alt="a wand icon" className="h-6 ml-2 text-sm" />}
                        </button>
                    </form>
                </div>
            </div>

            {/* Right Side: Artifact Viewer */}
            {isRightSideVisible && (
                <div className="w-full md:w-1/2 p-4 bg-white relative overflow-y-auto">
                    {/* Close Button */}
                    <button
                        onClick={closeRightPanel}
                        className="absolute top-2 right-2 text-gray-600 hover:text-red-600"
                    >
                        <FontAwesomeIcon icon={faClose} />
                    </button>

                    {currentArtifact ? (
                        <div className="space-y-4">
                            {currentArtifact.title !== "Untitled" && (
                                <h2 className="font-bold text-xl mb-4">{currentArtifact.title}</h2>
                            )}
                            <ReactMarkdown>{currentArtifact.content}</ReactMarkdown>
                            <div className='flex justify-end'>
                            <button
                                onClick={handleDownloadArtifact} 
                                className={` h-12 flex justify-center items-center bg-gradient-to-r from-linearBlue1 to-linearBlue2 text-white text-lg`}
                           >
                                <FontAwesomeIcon icon={faDownload} className="mr-2" />
                                Download
                            </button>
                            </div>
                        </div>
                    ) : (
                        <div className="h-full flex items-center justify-center text-gray-500">
                            No artifact selected.
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default UseTemplatePage;