import { REACT_APP_BACKEND_URL} from "../constants";
import { Template } from "./types";

export function fetchTemplates(): Promise<Template[]> {
  const token = localStorage.getItem('token');
 

  if (!token) {
  
    return Promise.reject("No token found, redirecting to login."); // Reject promise to prevent further execution
  }

  return fetch(`${REACT_APP_BACKEND_URL}/v1/template/templates`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Error fetching templates: ${res.statusText}`);
      }
      return res.json();
    })
    .then((data) => data.templates)
    .catch((error) => {
      console.error("Error fetching templates:", error);
      return []; 
    });
}
