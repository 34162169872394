import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useGetTemplates } from "../../../../data/documents/use-get-template";
import axios from "axios"; 
import { REACT_APP_BACKEND_URL } from "../../../../data/constants";

export default function TempRepository() {
  const { data: templates, isLoading } = useGetTemplates();
  const navigate = useNavigate();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Filter and paginate templates
  const paginatedTemplates = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return templates?.slice(startIndex, startIndex + itemsPerPage);
  }, [templates, currentPage, itemsPerPage]);

  const totalPages = Math.ceil((templates?.length || 0) / itemsPerPage);

  const handleUseTemplate = () => {
    //navigate("/wakili/useTemplate");
  };

  

  // Pagination handlers
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  // Loading and no-data states
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!templates || templates.length === 0) {
    return <div>No templates available.</div>;
  }

  return (
    <div className="DocRepositoryContainer">
      <div className="Chat_Documents text-sm font-semibold min-h-[70vh] max-h-[70vh] overflow-y-auto">
        {paginatedTemplates &&
          paginatedTemplates.map((template) => (
            <div
              className="Chat_Document flex justify-between items-center p-2 border-b"
              key={template._id}
            >
              <div className="Chat_Document_Title">{template.name}</div>
            
                <button
                  className="Chat_Document_Button"
                  onClick={handleUseTemplate}
                >
                  Use Template
                </button>
               
             
            </div>
          ))}
      </div>

      {/* Pagination Controls */}
      <div className="PaginationControls flex justify-between mt-4">
        <button
          className="px-4 py-2 bg-gray-200 text-xs rounded-md hover:bg-gray-300 disabled:opacity-50"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="text-xs">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="px-4 py-2 bg-gray-200 text-xs rounded-md hover:bg-gray-300 disabled:opacity-50"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}
