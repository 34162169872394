
import Cloud from "../../img/Cloud.png";
import Wand from "../../img/Wand.png";
import ChatDocsImg from "../../img/Landing_ChatwithDoc.png";

export default function ChatDocs() {
  return (
    <section id="chatwithmydocs" className="py-6 md:py-10">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row items-stretch md:space-x-8">
          
          {/* Text and Features Container */}
          <div className="flex-1 md:w-1/2 flex flex-col justify-between shadow-xs px-1 md:px-6">
            <div>
              <h3 className="text-center md:text-left text-xl lg:text-4xl font-medium text-gray-900">
                Chat With My Docs
              </h3>
              {/* Mobile Image - only visible on mobile */}
              <div className="md:hidden mt-4 mb-6">
                <img
                  src={ChatDocsImg}
                  alt="Chat With My Docs"
                  className="w-full h-auto object-contain rounded-xl"
                />
              </div>
              <p className="mt-3 text-[12px] text-gray-800 leading-loose">
                ChatWithMyDocs transforms the way you manage and interact with your proprietary legal documents. Securely upload your files, and let AI work for you—analyzing, extracting insights, and drafting documents in real-time, all tailored to your unique needs.
              </p>
            </div>

            {/* Features Section */}
            <div className="flex flex-col sm:flex-row justify-between gap-6 mt-3">
              {/* Feature 1 */}
              <div className="flex-1 flex flex-col items-center md:items-start text-center md:text-left px-4 py-3 md:py-6 rounded-xl">
                <img src={Cloud} alt="Upload and Chat" className="mb-3 self-center md:self-start h-8 w-8 md:h-auto md:w-auto " />
                <h3 className="text-sm md:text-base font-semibold text-gray-900">
                  Upload and Chat
                </h3>
                <p className="mt-2 text-[12px] text-gray-800 leading-loose">
                  Upload the relevant documents you wish to conduct your research on and chat away to extract relevant insights.
                </p>
              </div>

              {/* Feature 2 */}
              <div className="flex-1 flex flex-col items-center md:items-start text-center md:text-left px-4 py-3 md:py-6 rounded-xl">
                <img src={Wand} alt="Harness your firm’s Data" className="mb-3 self-center md:self-start h-8 w-8 md:h-auto md:w-auto " />
                <h3 className="text-sm md:text-base font-semibold text-gray-900">
                  Harness your firm’s Data
                </h3>
                <p className="mt-2 text-[12px] text-gray-800 leading-loose">
                  Securely connect your account to your firm's database and interact with the data via a chat interface.
                </p>
              </div>
            </div>
          </div>

          {/* Image Container - Hidden on mobile, visible on larger screens */}
          <div className="hidden md:flex flex-1 md:w-1/2 justify-center items-stretch">
            <img
              src={ChatDocsImg}
              alt="Chat With My Docs"
              className="w-full h-full object-contain rounded-2xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
