
import { REACT_APP_BACKEND_URL } from "../constants";
import { Collection } from "./types";

export async function fetchCollections(): Promise<Collection[] | null> {
 
  const token = localStorage.getItem('token'); 

  if (token === null) {
    console.error("User is not logged in."); 
    return null; 
  }


  const response = await fetch(
    `${REACT_APP_BACKEND_URL}/v1/collection/getAllCollections`, {
      headers: {
        'Authorization': `Bearer ${token}`, 
        'Content-Type': 'application/json'  
      }
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch collections");
  }

  const data: Collection[] = await response.json();
  return data;
}


