import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetChats } from "../../../data/chats/use-get-chats";
import LayersIcon from "@mui/icons-material/Layers";
import { useUpdateChats } from "../../../data/chats/use-createNewChat";
import Star from "../../../img/Star.png";
import { useChat } from "../../../context/chatContext";

interface Chat {
  _id: string;
  chatName: string;
  specificChat: boolean;
  drafterChat: boolean;
}

export default function SideBarTemplates(): React.ReactElement | null {
  const navigate = useNavigate();
  const { chatId } = useParams<{ chatId: string }>();
  const { data: chats } = useGetChats();
  const chatListRef = useRef<HTMLDivElement>(null);
  const { mutateAsync: createANewChat } = useUpdateChats();
  const { selectedChatId, setSelectedChatId } = useChat();
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (chatListRef.current) {
      const lastChatElement = chatListRef.current.lastChild;
      if (lastChatElement) {
        (lastChatElement as HTMLElement).scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [chats]);

  useEffect(() => {
    if (chats && chats.length > 0 && initialLoad) {
      setInitialLoad(false);
    }
  }, [chats, initialLoad]);

  useEffect(() => {
    if (!initialLoad && chatId && chatId !== selectedChatId) {
      setSelectedChatId(chatId);
    }
  }, [chatId, selectedChatId, setSelectedChatId, initialLoad]);

  const handleChatClick = (chatId: string): void => {
    setSelectedChatId(chatId);
    navigate(`/wakili/useTemplate/${chatId}`);
  };

  if (!chats) {
    return null;
  }

  // Filter chats where drafterChat is true
  const filteredChats = chats.filter((chat) => chat.drafterChat === true);

  return (
    <div className="px-2 mb-2 mt-5">
      <div className="pb-5 px-2 flex justify-between items-center text-xs font-bold gap-2 text-gray-700">
        <div className="flex gap-x-1 items-center">
          <img
            src={Star}
            alt="star image"
            className="Chats_Header_Icon h-[14px] w-[14px]"
          />
          <p>Chat History</p>
        </div>
      </div>
      <div className="Chats" ref={chatListRef}>
        {filteredChats.map((chat) => (
          <div
            className={`flex ${
              chat._id === selectedChatId ? "Selected_Chat" : ""
            }`}
            onClick={() => handleChatClick(chat._id)}
            key={chat._id}
          >
            <LayersIcon className="Chats_Icon" />
            <div
              className={`overflow-hidden text-ellipsis whitespace-nowrap text-xs text-gray-400 cursor-pointer flex-grow ${
                chat._id === selectedChatId ? "Selected_Chat" : ""
              }`}
            >
              {chat.chatName}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
