import { useState, useMemo } from "react";
import { useGetDocuments } from "../../../../data/documents/use-get-documents";
import { useDeleteDocument } from "../../../../data/documents/use-delete-document";
import { useCollection } from "../../../../context/collectionContext";

export default function DocRepository() {
  const { data: documents, isLoading } = useGetDocuments();
  const deleteDocumentMutation = useDeleteDocument();
  const { collectionValue } = useCollection();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Adjust this value as needed

  // Filter documents based on collectionValue._id (if collectionValue exists)
  const filteredDocuments = useMemo(() => {
    return documents?.filter(
      (doc) => collectionValue?._id && doc.collectionId === collectionValue._id
    );
  }, [documents, collectionValue]);

  // Paginate the filtered documents
  const paginatedDocuments = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredDocuments?.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredDocuments, currentPage, itemsPerPage]);

  // Total pages
  const totalPages = Math.ceil((filteredDocuments?.length || 0) / itemsPerPage);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!filteredDocuments || filteredDocuments.length === 0) {
    return <div>No documents for this collection.</div>;
  }

  const handleDelete = (id:string) => {
    deleteDocumentMutation.mutate(id);
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  return (
    <div className="DocRepositoryContainer">
      <div className="Chat_Documents text-xs font-semibold max-h-[70vh] overflow-y-auto">
        {paginatedDocuments && paginatedDocuments.map((document) => (
          <div className="Chat_Document flex justify-between items-center p-2 border-b" key={document._id}>
            <div className="Chat_Document_Title">{document.filename}</div>
            <div className="Chat_Document_Button">
              <button
                className="text-xs text-red-600 hover:underline"
                onClick={() => handleDelete(document._id)}
              >
                Remove
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="PaginationControls flex justify-between mt-4">
        <button
          className="px-4 py-2 bg-gray-200 text-xs rounded-md hover:bg-gray-300 disabled:opacity-50"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="text-xs">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="px-4 py-2 bg-gray-200 text-xs rounded-md hover:bg-gray-300 disabled:opacity-50"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}
