import React, { useState, ChangeEvent, FormEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { REACT_APP_BACKEND_URL } from "../../../../data/constants";
import { useFetchSingleUser } from "../../../../data/users/use-fetch-single-user";
import { useNavigate } from "react-router-dom";
import Document from "../../../../img/Document.png";
import Upload from "../../../../img/Upload.png";
import Arrow from "../../../../img/Arrow icon.png"

const DocDrafter: React.FC = () => {
    const [file, setFile] = useState<File | null>(null);
    const [prompt, setPrompt] = useState<string>("");
    const [message, setMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { data: user } = useFetchSingleUser();
    const navigate = useNavigate();

    // Utility: Sanitize the file name
    const sanitizeFileName = (name: string): string => name.replace(/[\s_-]/g, "");

    // Utility: Validate token
    const validateToken = (): string | null => {
        const token = localStorage.getItem("token");
        if (!token) {
            setMessage("User is not logged in. Please log in and try again.");
        }
        return token;
    };

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0]);
        }
    };

    const handleDeselectFile = () => setFile(null);

    const handlePromptChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPrompt(event.target.value);
    };

    const uploadTemplate = async () => {
        if (!file || !user) {
            setMessage("Please select a file and ensure user data is loaded.");
            return;
        }

        const sanitizedFileName = sanitizeFileName(file.name);
        const context = `prvTemplate_${user.id}_${sanitizedFileName}`;

        try {
            setLoading(true);
            setMessage(null);

            // Step 1: Upload the template to the backend
            const formData = new FormData();
            formData.append("file", file);
            formData.append("name", sanitizedFileName);
            formData.append("userId", user.id);

            const backendResponse = await axios
                .post(`${REACT_APP_BACKEND_URL}/v1/template/addTemplate`, formData)
                .catch((error) => {
                    setMessage(
                        `Failed to upload the template to the backend. Error: ${error.response?.data?.message || error.message
                        }`
                    );
                    return null; // Stop further processing
                });
            if (!backendResponse) return;

            // Step 2: Create context in LLM
            const contextResponse = await axios
                .post("https://ai-server.lawlyfy.ai/api/wakili/create_context", {
                    context,
                    hypercontext: "ai_doc_drafter",
                })
                .catch((error) => {
                    setMessage(
                        `Failed to create context in LLM. Error: ${error.response?.data?.message || error.message
                        }`
                    );
                    return null;
                });
            if (!contextResponse) return;

            // Step 3: Upload the template to the LLM
            const llmResponse = await axios
                .post("https://ai-server.lawlyfy.ai/api/wakili/upload", {
                    context,
                    filename: sanitizedFileName,
                })
                .catch((error) => {
                    setMessage(
                        `Failed to upload the template to the LLM. Error: ${error.response?.data?.message || error.message
                        }`
                    );
                    return null;
                });
            if (!llmResponse) return;

            // Step 4: Create new chat
            const token = validateToken();
            if (!token) return;

            const chatResponse = await axios
                .post(
                    `${REACT_APP_BACKEND_URL}/v1/chat/createChatDrafter`,
                    { specificChat: false },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .catch((error) => {
                    setMessage(
                        `Failed to create a new chat. Error: ${error.response?.data?.message || error.message
                        }`
                    );
                    return null;
                });
            if (!chatResponse) return;

            const chatId = chatResponse.data._id;
            if (!chatId) {
                setMessage("Failed to retrieve chat ID.");
                return;
            }

            // Navigate to useTemplate page with context
            navigate(`/wakili/useTemplate/${chatId}`, {
                state: {
                    context,
                    templateName: sanitizedFileName,
                },
            });

            setMessage("Template uploaded successfully!");
        } catch (error) {
            setMessage("An unexpected error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            setLoading(true);
            setMessage(null);

            const token = validateToken();
            if (!token) return;

            const chatResponse = await axios
                .post(
                    `${REACT_APP_BACKEND_URL}/v1/chat/createChatDrafter`,
                    { specificChat: false },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .catch((error) => {
                    setMessage(
                        `Failed to create a new chat. Error: ${error.response?.data?.message || error.message
                        }`
                    );
                    return null;
                });
            if (!chatResponse) return;

            const chatId = chatResponse.data._id;
            if (!chatId) {
                setMessage("Failed to retrieve chat ID.");
                return;
            }

            navigate(`/wakili/useTemplate/${chatId}`, { state: { prompt } });
            setMessage("Chat created successfully!");
        } catch (error) {
            setMessage("An unexpected error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="min-h-[85vh]">
            <div className="w-full md:w-3/4 mx-auto">
                <div className="flex justify-center">
                    <h1 className="bg-green-200 text-gray-600 p-3 rounded-md">
                        This is a BETA version of the Lawlyfy AI Document Drafting Co-pilot
                    </h1>
                </div>
                <div className="flex flex-row justify-between p-6 text-sm">
                    <div className='flex w-1/2 gap-2 items-center'>
                        <div className="relative w-5 h-5 rounded-full bg-white border-2 border-purple-500">
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="w-2 h-2 rounded-full bg-purple-500"></div>
                            </div>
                        </div>
                        <h1 className="font-semibold">
                            Securely Upload Your Template <span className="text-gray-400">(optional)</span>
                        </h1>
                    </div>


                    <div className='flex w-1/2 gap-2 items-center pl-6'>
                        <div className="relative w-5 h-5 rounded-full bg-white border-2 border-purple-500">
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="w-2 h-2 rounded-full bg-purple-500"></div>
                            </div>
                        </div>
                        <h1 className="font-semibold">
                            Co-draft your doc with Generative AI
                        </h1>
                    </div>




                </div>
                <div className="p-6 text-sm flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-8">
                    {/* Upload Section */}
                    <div className="flex flex-col items-center justify-between Light_Gray Box_Shadow shadow-md p-6 rounded-lg flex-1 h-80">


                        <p className="text-gray-500 mb-4">
                            Upload your file or drag and drop it here. PDF and .docx files are supported, with maximum size of 5 mbs.

                        </p>
                        {!file ? (
                            <>
                                <img src={Document} alt="document" className='h-24' />
                                <div className="w-full">
                                    <label
                                        htmlFor="file-upload"
                                        className="flex items-center justify-around w-32 mx-auto block cursor-pointer Gradient_Background text-white font-bold py-2 px-4 rounded-lg text-center"
                                    >
                                        <input
                                            id="file-upload"
                                            type="file"
                                            accept="application/pdf"
                                            className="hidden"
                                            onChange={handleFileUpload}
                                        />

                                        <img src={Upload} alt="" className="mr-2" />
                                        Upload
                                    </label>
                                </div>
                            </>
                        ) : (
                            <div className="text-center">
                                <p className="text-gray-700">
                                    Selected: {file.name}{" "}
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        className="text-red-500 cursor-pointer ml-2"
                                        onClick={handleDeselectFile}
                                    />
                                </p>
                                <button
                                    onClick={uploadTemplate}
                                    disabled={loading}
                                    className={`mt-4 px-4 py-2 rounded-lg ${loading
                                        ? "bg-gray-500 cursor-not-allowed"
                                        : "bg-green-500 hover:bg-green-600 text-white"
                                        }`}
                                >
                                    {loading ? "Uploading..." : "Submit Template"}
                                </button>
                            </div>
                        )}
                    </div>

                    {/* Drafting Section */}
                    <div className="flex flex-col items-center justify-center Light_Gray Box_Shadow shadow-md px-6  rounded-lg flex-1 h-80">
                      <div className='bg-white p-2 rounded-lg'>
                        <p className="text-gray-500 mb-4">
                            Craft a detailed prompt providing context for the contract or clause you
                            need to draft...
                        </p>
                        <form onSubmit={handleSubmit} className="w-full">
                            <textarea
                                name="prompt"
                                id="prompt"
                                rows={5}
                                className="w-full border border-gray-300 rounded-lg p-2 mb-4"
                                value={prompt}
                                onChange={handlePromptChange}
                            ></textarea>
                            <div className="w-full flex justify-end items-end">

                                <div className='flex Lighter_Shade rounded-2xl items-center px-2'>
                                    <button
                                        type="submit"
                                        className="w-32 text-white font-bold p-2 flex items-center"
                                    >
                                        Start Drafting
                                    </button>
                                    <img src={Arrow} alt="arrow" className="h-6"/>
                                </div>

                            </div>
                        </form>
                        </div>
                        
                    </div>
                </div>
                {message && (
                    <div className="mt-6 text-center">
                        <p
                            className={`${message.includes("success")
                                ? "text-green-500"
                                : "text-red-500"
                                }`}
                        >
                            {message}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DocDrafter;
